import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
import contact from '../../assets/images/contact.png'
import { useState } from "react";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const ContactForm = () => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");

    const { executeRecaptcha } = useGoogleReCaptcha();

    let handleSubmit = async (e) => {
        e.preventDefault();

        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }

        const token2 = await executeRecaptcha('yourAction');

        try {
            let res = await fetch("https://sbm.perisun.com/crm/lead", {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    tenant: "Perisun",
                    source: "Website",
                    campaign: "contact-us-form",
                    lastName: lastName,
                    firstName: firstName,
                    message: message,
                    email: email,
                    phone: phone,
                    token: token2
                }),
            });
            let resJson = await res.json();
            if (res.status === 200) {
                setFirstName("");
                setLastName("");
                setEmail("");
                setPhone("");
                setMessage("Message posted successfully");
            } else {
                setMessage("Some error occured");
            }
        } catch (err) {
            console.log(err);
        }
    };
    return (
        <section className="contact-area pb-70">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="contact" />
                        Get in Touch
                    </span>
                    <h2>You want we call you?</h2>
                    <p>We dont share your data with anyone. Required fields are marked *</p>
                </div>

                <div className="row">
                    <div className="col-lg-6 col-md-12">
                        <div className="contact-image">
                            <img src={contact} alt="contact" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="contact-form">
                            <form id="contactForm" onSubmit={handleSubmit} >

                                <div className="row">
                                    <div className="col-lg-12 col-md-6">
                                        <div className="form-group">
                                            <input type="text" name="firstName" onChange={(e) => setFirstName(e.target.value)} value={firstName} className="form-control" required placeholder="Your first name" />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-6">
                                        <div className="form-group">
                                            <input type="text" name="lastName" onChange={(e) => setLastName(e.target.value)} value={lastName} className="form-control" required placeholder="Your last name" />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-6">
                                        <div className="form-group">
                                            <input type="email" name="email" onChange={(e) => setEmail(e.target.value)} value={email} className="form-control" required placeholder="Your email address" />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <input type="text" name="phone" onChange={(e) => setPhone(e.target.value)} value={phone} className="form-control" required placeholder="Your phone number" />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <textarea name="message" onChange={(e) => setMessage(e.target.value)} value={message} className="form-control" cols="30" rows="6" required placeholder="Write your message..." />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <button type="submit" className="default-btn">
                                            <i className="flaticon-tick"></i>
                                            Send Message <span></span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactForm