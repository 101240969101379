import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import ContactInfo from '../components/Contact/ContactInfo'
import ContactForm from '../components/Contact/ContactForm'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const BookNow = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Contact"
                homePageText="Home"
                homePageUrl="/"
                activePageText="Book Now"
            />
            <GoogleReCaptchaProvider reCaptchaKey="6LemzdokAAAAAPRYfDfdFVKDLtLrwXwSyAQ9Iv6Y">
                <ContactForm />
            </GoogleReCaptchaProvider>

            <Footer />
        </Layout>
    );
}

export default BookNow